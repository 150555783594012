import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Loadable from "@loadable/component"
import moment from "moment-timezone"
import AnomalySectionInfo from "./anomalySectionInfo"

export class ErrorChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      series: [],
      sectionData: {},
      options: {},
    }
  }

  componentDidMount() {
    const _that = this
    this.setState({
      series: [
        {
          name: this.props.intl.formatMessage({
            id: "model-detail-chart-legend-detected-anomalies",
          }),
          data: this.props.anomalyMetrics.map(range => ({
            x: " ",
            y: [new Date(range.start).getTime(), new Date(range.end).getTime()],
          })),
        },
        {
          name: this.props.intl.formatMessage({
            id: "model-detail-chart-legend-inference-error",
          }),
          data: this.props.errorMetrics.map(range => ({
            x: " ",
            y: [new Date(range.start).getTime(), new Date(range.end).getTime()],
          })),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
          events: {
            dataPointSelection: this.setSectionData.bind(this),
            beforeResetZoom: function(chartContext, opts) {
              return {
                xaxis: {
                  max: new Date().getTime(),
                  min: Math.min(
                    new Date(_that.props.errorMetrics[0].start).getTime(),
                    _that.props.anomalyMetrics[0]
                      ? new Date(_that.props.anomalyMetrics[0].start).getTime()
                      : new Date().getTime()
                  ),
                },
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            rangeBarGroupRows: true,
          },
        },
        colors: ["#1ECE9C", "#DA1E27"],
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
        },
        xaxis: {
          type: "datetime",
          max: new Date().getTime(),
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
    })
  }

  componentDidUpdate() {
    if (
      this.props.anomalyMetrics.length !== this.state.series[0].data.length ||
      this.props.errorMetrics.length !== this.state.series[1].data.length
    ) {
      this.componentDidMount()
    }
  }
  setSectionData(event, chartContext, config) {
    const metrics = config.seriesIndex === 0 ? "anomalyMetrics" : "errorMetrics"
    this.setState({
      sectionData: this.props[metrics][config.dataPointIndex],
    })
  }

  render() {
    return (
      <div id="chart">
        {this.state.series.length &&
        (this.state.series[0]["data"].length ||
          this.state.series[1]["data"].length) ? (
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="rangeBar"
            height={350}
          />
        ) : (
          <strong>
            {this.props.intl.formatMessage({
              id: `page-dataset-inference-no-data-${this.props.inferenceStatus}`,
            })}
          </strong>
        )}
        <AnomalySectionInfo
          sectionData={this.state.sectionData}
        ></AnomalySectionInfo>
      </div>
    )
  }
}
export default injectIntl(ErrorChart)

const Chart = Loadable(() => import("react-apexcharts"))
