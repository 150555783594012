import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Loadable from "@loadable/component"
import AnomalySectionInfo from "./anomalySectionInfo"

export class AnomalyChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      series: [],
      sectionData: {},

      options: {},
    }
  }

  componentDidMount() {
    const series = [
      {
        name: this.props.intl.formatMessage({
          id: "model-detail-chart-legend-detected-anomalies",
        }),
        data: this.props.predictedRanges.map(range => ({
          x: " ",
          y: [new Date(range.start).getTime(), new Date(range.end).getTime()],
        })),
      },
      {
        name: this.props.intl.formatMessage({
          id: "model-detail-chart-legend-tagged-range",
        }),
        data: this.props.labeledRanges.map(range => ({
          x: " ",
          y: [new Date(range.start).getTime(), new Date(range.end).getTime()],
        })),
      },
    ]

    const options = {
      chart: {
        height: 350,
        type: "rangeBar",
        events: {
          dataPointSelection: this.setSectionData.bind(this),
        },
      },
      colors: ["#1ECE9C", "#9D8EDD"],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
      },
      xaxis: {
        type: "datetime",
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
    }

    this.setState({
      series,
      options,
    })
  }

  setSectionData(event, chartContext, config) {
    if (config.seriesIndex === 0) {
      this.setState({
        sectionData: this.props.predictedRanges[config.dataPointIndex],
      })
    }
  }

  render() {
    return (
      <div id="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="rangeBar"
          height={350}
        />
        <AnomalySectionInfo
          sectionData={this.state.sectionData}
        ></AnomalySectionInfo>
      </div>
    )
  }
}
export default injectIntl(AnomalyChart)

const Chart = Loadable(() => import("react-apexcharts"))
