import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Loadable from "@loadable/component"
import moment from "moment-timezone"

export class MainSensorChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return `${val}%`
          },
        },
      },
    }
  }

  render() {
    const data = this.props.data.sort((a, b) => b.value - a.value)
    return (
      <Chart
        series={[
          { data: data.map(item => Math.round(item.value * 10000) / 100) },
        ]}
        options={{
          ...this.state.options,
          xaxis: { categories: data.map(item => item.name.split("\\")) },
        }}
        type="bar"
        height={250}
      ></Chart>
    )
  }
}

export default injectIntl(MainSensorChart)

const Chart = Loadable(() => import("react-apexcharts"))
