import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import { Button, Col, Icon, Row, Tabs, message, Spin, Switch } from "antd"
import { Template } from "../../layouts/base"
import { BsArrowBarLeft } from "react-icons/bs"
import {
  createInference,
  deleteInference,
  deleteModel,
  getInference,
  getModel,
  getModels,
  toggleInference,
} from "../../services/dataset"
import ComponentList from "../../components/dataset/componentList"
import ModelList from "../../components/dataset/modelList"
import ModelForm from "../../components/dataset/modelForm"
import moment from "moment-timezone"
import AnomalyChart from "../../components/dataset/anomalyChart"
import InferenceForm from "../../components/dataset/inferenceForm"
import ModelDeleteModal from "../../components/dataset/modelDeleteModal"
import InferenceDeleteModal from "../../components/dataset/inferenceDeleteModal"
import ErrorChart from "../../components/dataset/errorChart"
import { checkPermissions } from "../../services/auth/permissions/permissions"

const { TabPane } = Tabs
const ONE_MIN_IN_MS = 60000

class ModelDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modelIdUrlParam:
        props.location && props.location.pathname.split("/").pop(),
      loadingModels: true,
      model: null,
      inference: null,
      models: [],
      modelNames: null,
      showModelForm: false,
      loadingInference: true,
      deleteModelModalVisible: false,
      deleteInferenceModalVisible: false,
      inferenceSwitch: false,
      lastUpdate: null,
      updatingInference: false,
    }
    this.interval = null

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })
  }

  componentDidMount() {
    this._loadModelDetail()
    this._loadInferenceDetail()
  }

  componentWillUnmount() {
    this._clearInterval()
  }

  _loadModelDetail() {
    if (this.state.modelIdUrlParam) {
      getModel({}, this.state.modelIdUrlParam).then(model => {
        this._loadModels(model.dataset_name, model.model_name)
        this.setState({ model })
      })
    } else {
      message.error(
        this.props.intl.formatMessage({
          id: "page-dataset-load-models-ko",
        })
      )
      console.error('[ERROR]: No "id" retrieved from URL in order to query it.')
    }
  }

  _loadInferenceDetail() {
    if (this.state.modelIdUrlParam) {
      getInference({}, this.state.modelIdUrlParam)
        .then(inference => {
          this.setState({
            inference,
            loadingInference: false,
            inferenceSwitch: inference.status === "RUNNING",
            updatingInference: false,
          })
        })
        .catch(err => {
          this.setState({ inference: {} })
        })
    }
  }

  _loadModels(datasetName, modelName) {
    this._updateLastUpdateDate()
    this._clearInterval()
    getModels()
      .then(models => {
        this._loadModelsPeriodically()
        this.setState({
          models: models.filter(
            model =>
              model.dataset_name === datasetName &&
              model.model_name !== modelName
          ),
          loadingModels: false,
        })
      })
      .catch(() => {
        message.error(
          this.props.intl.formatMessage({
            id: "page-dataset-load-models-ko",
          })
        )
        console.error(
          '[ERROR]: No "id" retrieved from URL in order to query it.'
        )
      })
  }

  _loadModelsPeriodically() {
    this._clearInterval()
    this._updateLastUpdateDate()
    this.interval = setInterval(
      () => this._loadDatasetsInBackground(),
      ONE_MIN_IN_MS
    )
  }

  _loadDatasetsInBackground() {
    this._loadModelDetail()
    this._loadInferenceDetail()
  }

  _clearInterval() {
    if (this.interval !== null) {
      clearInterval(this.interval)
    }
  }

  _updateLastUpdateDate() {
    this.setState({ lastUpdate: moment().format("HH:mm:ss") })
  }

  _getTimeFormated(time) {
    let that = this
    function getUnits(ammount, type) {
      return ammount === 0
        ? ""
        : `${ammount} ${that._formatMessage(type)}${ammount == 1 ? " " : "s "} `
    }
    let days = Math.floor(time / (3600 * 24))
    time = time % (3600 * 24)
    let hours = Math.floor(time / 3600)
    time = time % 3600
    let minutes = Math.floor(time / 60)
    let seconds = Math.round(time % 60)
    return `${getUnits(days, "day")}${getUnits(hours, "hour")}${getUnits(
      minutes,
      "min"
    )}${getUnits(seconds, "sec")}`
  }

  _onDeleteModelCancel() {
    this.setState({ deleteModelModalVisible: false })
  }

  _onDeleteModelConfirm() {
    deleteModel(this.state.model.model_name).then(response => {
      if (response.message === "Model deleted successfully!.") {
        message.info(this._formatMessage("page-dataset-delete-model-ok"))
        navigate(`/dataset/${this.state.model.dataset_name}`)
      } else {
        message.error(this._formatMessage("page-dataset-delete-model-ko"))
      }
      this.setState({ deleteModelModalVisible: false })
    })
  }

  _onDeleteInferenceCancel() {
    this.setState({ deleteInferenceModalVisible: false })
  }

  _onDeleteInferenceConfirm() {
    deleteInference(this.state.model.model_name)
      .then(response => {
        if (response.message === "Inferece delete successfully!") {
          message.info(this._formatMessage("page-dataset-delete-inference-ok"))
          navigate(`/dataset/${this.state.model.dataset_name}`)
        } else {
          message.error(this._formatMessage("page-dataset-delete-inference-ko"))
        }
      })
      .catch(err => {
        message.error(
          `${this._formatMessage(
            "page-dataset-delete-inference-ko"
          )} Status Code: ${err}`
        )
      })
      .finally(() => {
        this.setState({ deleteModelModalVisible: false })
      })
  }

  _onInferenceFormSubmit(data) {
    const inference = {
      model_name: this.state.model.model_name,
      data_frequency: data.sampling_rate,
      delay: data.delay,
    }
    createInference(inference)
      .then(inference => {
        message.info(this._formatMessage("page-dataset-create-inference-ok"))
        this.setState({
          inference,
          showModelForm: false,
          loadingInference: true,
        })
        const interval = setInterval(() => {
          this._loadInferenceDetail()
          if (this.state.inference.status === "PENDING") {
            clearInterval(interval)
          }
        }, 5000)
      })
      .catch(err => {
        message.error(
          `${this._formatMessage(
            "page-dataset-create-inference-ko"
          )} Status Code: ${err}`
        )
      })
  }

  _onInferenceActiveToggle() {
    this.setState({ updatingInference: true })
    const actions = {
      STOPPED: "start",
      RUNNING: "stop",
    }
    const { inference_name, status } = this.state.inference
    toggleInference(inference_name, actions[status])
      .then(resp => {
        this._loadInferenceDetail()
        message.success(
          this._formatMessage(`page-dataset-switch-inference-ok-${resp.status}`)
        )
      })
      .catch(err => {
        message.error(this._formatMessage("page-dataset-switch-inference-ko"))
        this.setState({ updatingInference: false })
      })
  }

  _renderModelStatus(status) {
    const options = {
      IN_PROGRESS: {
        message: "model-status-in-progress",
        image: <Icon type="clock-circle"></Icon>,
      },
      SUCCESS: {
        message: "model-status-active",
        image: <div className="ingest-status ingest-status--ok"></div>,
      },
      FAILED: {
        message: "model-status-failed",
        image: <div className="ingest-status ingest-status--ko"></div>,
      },
    }
    return (
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "1em" }}
      >
        {options[status].image}
        &nbsp; &nbsp; &nbsp;
        {this.props.intl.formatMessage({ id: options[status].message })}
      </div>
    )
  }

  _renderResume(isInference) {
    return (
      <Row className="model-resume">
        <Col span={12}>
          <div className="resume-item">
            <div
              className="model-item-title"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {this._formatMessage("model-detail-resume-title")}
              {this._renderModelStatus(this.state.model.status)}
            </div>
            {this.state.model.metrics ? (
              <div className="resume-content">
                <div>
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                    }}
                  >
                    {
                      this.state.model.metrics.unknown_event_metrics
                        .num_identified
                    }
                  </span>
                  &nbsp;
                  <span>
                    {this._formatMessage("model-detail-resume-content")}
                  </span>
                </div>
                <div>
                  <span>
                    {this._formatMessage("model-detail-resume-duration")}
                  </span>
                  &nbsp;
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                    }}
                  >
                    {this._getTimeFormated(
                      this.state.model.metrics.unknown_event_metrics
                        .total_duration_in_seconds
                    )}
                  </span>
                </div>
                <div>
                  <span>
                    {this._formatMessage("new-model-sampling-rate-title")}:
                  </span>
                  &nbsp;
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                    }}
                  >
                    {this._formatMessage(this.state.model.target_rate)}
                  </span>
                </div>
              </div>
            ) : (
              <div>{this._formatMessage("model-detail-no-metrics")}</div>
            )}
          </div>
          {this.state.model.status === "FAILED" ? (
            <div>
              <div
                className="model-item-title"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1em",
                }}
              >
                {this._formatMessage("model-detail-error-in-model")}
              </div>
              <div>{this.state.model.error}</div>
            </div>
          ) : null}
        </Col>
        {isInference ? (
          <div>
            <Col
              span={8}
              style={{
                borderLeft: "2px solid #e0e0e0",
                paddingLeft: "1em",
              }}
            >
              <div className="resume-item">
                <div className="model-item-title">
                  {this._formatMessage("model-detail-creating-date-title")}
                </div>
                <div className="resume-content">
                  {moment(this.state.inference.created_at)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss")}
                </div>
              </div>
              <div className="resume-item">
                <div className="model-item-title">
                  {this._formatMessage("new-inference-form-frequency")}
                </div>
                <div className="resume-content">
                  {this._formatMessage(
                    this.state.inference.data_upload_frequency
                  )}
                </div>
              </div>
              <div className="resume-item">
                <div className="model-item-title">Delay</div>
                <div className="resume-content">
                  {this.state.inference.delay_offset}&nbsp;
                  {this._formatMessage("min")}s
                </div>
              </div>
            </Col>
            <Col span={3}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="model-item-title"
                  style={{
                    color: checkPermissions(["inference_status:write"])
                      ? "unset"
                      : "gray",
                  }}
                >
                  {this._formatMessage("inference-detail-active-title")}
                </div>
                <Switch
                  id="switch"
                  size="large"
                  checked={this.state.inferenceSwitch}
                  disabled={!checkPermissions(["inference_status:write"])}
                  onChange={() => this._onInferenceActiveToggle()}
                  loading={this.state.updatingInference}
                />
              </Row>
              <Row>
                {checkPermissions(["inference:delete"]) ? (
                  <Button
                    type="primary"
                    onClick={() =>
                      this.setState({ deleteInferenceModalVisible: true })
                    }
                    className="knolar-button dataset-button"
                    disabled={this.state.inference.status === "RUNNING"}
                  >
                    {this._formatMessage("delete-inference")}
                  </Button>
                ) : null}
              </Row>
            </Col>
          </div>
        ) : (
          <Col
            span={12}
            style={{
              borderLeft: "2px solid #e0e0e0",
              paddingLeft: "1em",
            }}
          >
            <div className="resume-item">
              <div className="model-item-title">
                {this._formatMessage("model-detail-evaluating-range-title")}
              </div>
              <div className="resume-content">
                {`
                ${moment(this.state.model.eval_date_start).format(
                  "YYYY-MM-DD"
                )} -
                  ${moment(this.state.model.eval_date_end).format("YYYY-MM-DD")}
                    `}
              </div>
            </div>
            <div className="resume-item">
              <div className="model-item-title">
                {this._formatMessage("model-detail-training-range-title")}
              </div>
              <div className="resume-content">
                {`
                ${moment(this.state.model.train_date_start).format(
                  "YYYY-MM-DD"
                )} -
                  ${moment(this.state.model.train_date_end).format(
                    "YYYY-MM-DD"
                  )}
                    `}
              </div>
            </div>
            <div className="resume-item">
              <div className="model-item-title">
                {this._formatMessage(
                  "model-detail-execute-training-time-title"
                )}
              </div>
              {this.state.model.train_execute_date_end ? (
                <div className="resume-content">
                  {this._getTimeFormated(
                    moment
                      .duration(
                        moment(this.state.model.train_execute_date_end).diff(
                          moment(this.state.model.train_execute_date_start)
                        )
                      )
                      .asSeconds()
                  )}
                </div>
              ) : (
                this._formatMessage("page-dataset-create-model-ko")
              )}
            </div>
          </Col>
        )}
      </Row>
    )
  }

  render() {
    const _thatIntl = this.props.intl
    return (
      <Template selected={["model", "model-index"]}>
        <Row>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <CustomBreadcrumb
              style={{ flexGrow: "1" }}
              crumbs={[
                _thatIntl.formatMessage({ id: "menu-ml" }),
                _thatIntl.formatMessage({ id: "menu-datasets" }),
                this.state.model && this.state.model.dataset_name,
                this.state.model && this.state.model.model_name,
              ]}
            />
            {this.state.lastUpdate !== null ? (
              <div>
                {this.props.intl.formatMessage({ id: "last-update" })}:{" "}
                {this.state.lastUpdate}
              </div>
            ) : null}
          </div>
        </Row>
        {this.state.loadingModels ? (
          <Spin size="large" />
        ) : (
          <div>
            <Row>
              <Col className="knolar-intro" span={12}>
                <Icon
                  component={BsArrowBarLeft}
                  onClick={() =>
                    navigate(`/dataset/${this.state.model.dataset_name}`)
                  }
                ></Icon>
                {this.state.modelIdUrlParam}
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {checkPermissions(["model:delete"]) ? (
                  <Button
                    type="primary"
                    onClick={() =>
                      this.setState({ deleteModelModalVisible: true })
                    }
                    className="knolar-button dataset-button"
                  >
                    {_thatIntl.formatMessage({ id: "delete-model" })}
                  </Button>
                ) : null}
              </Col>
            </Row>
            <Tabs className="dataset-tabs" defaultActiveKey="1">
              <TabPane
                tab={_thatIntl.formatMessage({
                  id: "model-detail-metrics",
                })}
                key="1"
                forceRender={true}
              >
                <Row className="content">
                  {this._renderResume(false)}
                  <Row className="model-chart">
                    <div className="model-item-title">
                      {this._formatMessage("model-detail-chart-title")}
                    </div>
                    {this.state.model.metrics ? (
                      <AnomalyChart
                        labeledRanges={this.state.model.metrics.labeled_ranges}
                        predictedRanges={
                          this.state.model.metrics.predicted_ranges
                        }
                      ></AnomalyChart>
                    ) : (
                      <div>
                        {this._formatMessage("model-detail-no-metrics")}
                      </div>
                    )}
                  </Row>
                </Row>
              </TabPane>
              <TabPane
                tab={_thatIntl.formatMessage({ id: "model-detail-inferences" })}
                key="2"
                forceRender={true}
                disabled={
                  !this.state.model.metrics ||
                  !checkPermissions(["inference:read"])
                }
              >
                <div className="content">
                  {this.state.inference ? (
                    Object.keys(this.state.inference).length == 0 ? (
                      <InferenceForm
                        minFrequency={this.state.model.target_rate}
                        onSubmit={data => this._onInferenceFormSubmit(data)}
                      />
                    ) : this.state.loadingInference ? (
                      <div>
                        <div className="knolar-intro">
                          {this._formatMessage(
                            this.state.inference.status === "PENDING"
                              ? "page-dataset-inference-delay-message"
                              : "page-dataset-inference-creating-message"
                          )}
                        </div>
                        <Spin size="large"></Spin>
                      </div>
                    ) : (
                      <div>
                        {this.state.inference.status !== "PENDING" &&
                          this._renderResume(true)}
                        <ErrorChart
                          anomalyMetrics={this.state.inference.metrics.filter(
                            e => e.type === "anomaly"
                          )}
                          errorMetrics={this.state.inference.metrics.filter(
                            e => e.type === "error"
                          )}
                          inferenceStatus={this.state.inference.status}
                        ></ErrorChart>
                      </div>
                    )
                  ) : (
                    <Spin size="large" />
                  )}
                </div>
              </TabPane>
            </Tabs>
            <Row>
              <div className="model-item-title" style={{ marginTop: "40px" }}>
                {this._formatMessage("model-detail-other-models")}{" "}
                {this.state.model.dataset_name}
              </div>
              <ModelList models={this.state.models}></ModelList>
            </Row>
          </div>
        )}
        <ModelDeleteModal
          visible={this.state.deleteModelModalVisible}
          onOk={() => this._onDeleteModelConfirm()}
          onCancel={() => this._onDeleteModelCancel()}
        ></ModelDeleteModal>
        <InferenceDeleteModal
          visible={this.state.deleteInferenceModalVisible}
          onOk={() => this._onDeleteInferenceConfirm()}
          onCancel={() => this._onDeleteInferenceCancel()}
        ></InferenceDeleteModal>
      </Template>
    )
  }
}

export default injectIntl(withAuth(ModelDetail))
