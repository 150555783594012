import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import moment from "moment-timezone"
import MainSensorChart from "./mainSensorChart"

export class AnomalySectionInfo extends React.Component {
  constructor(props) {
    super(props)
    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })
  }

  getSectionTime(start, end) {
    const time = moment.duration(moment(end).diff(moment(start))).asMinutes()
    const hours = Math.floor(time / 60)
    const minutes = Math.round(time % 60)
    return `${hours} ${this._formatMessage("hour")}${
      hours !== 1 ? "s" : ""
    } ${minutes} ${this._formatMessage("min")}${minutes !== 1 ? "s" : ""}`
  }

  render() {
    return Object.keys(this.props.sectionData).length ? (
      <div className="section-info">
        <div className="model-item-title">
          {this.props.intl.formatMessage({
            id: "model-detail-resume-section-detail-title",
          })}
        </div>
        <div
          className="section-resume"
          style={{
            display: "flex",
            maxWidth: "450px",
            justifyContent: "space-between",
          }}
        >
          <div className="section-resume-column">
            <div
              className="section-resume-item start"
              style={{ display: "flex" }}
            >
              <div className="section-resume-item-title">
                {this._formatMessage("model-detail-resume-section-range-init")}:{" "}
              </div>
              <div className="section-resume-item-value">
                {moment(this.props.sectionData.start).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </div>
            </div>
            <div
              className="section-resume-item end"
              style={{ display: "flex" }}
            >
              <div className="section-resume-item-title">
                {this._formatMessage(
                  "model-detail-resume-section-range-finish"
                )}
                :{" "}
              </div>
              <div className="section-resume-item-value">
                {moment(this.props.sectionData.end).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </div>
            </div>
          </div>
          <div className="section-resume-item length">
            <div className="section-resume-item-title">
              {this._formatMessage("model-detail-resume-section-range-length")}:{" "}
            </div>
            <div className="section-resume-item-value">
              {this.getSectionTime(
                this.props.sectionData.start,
                this.props.sectionData.end
              )}
            </div>
          </div>
        </div>
        {this.props.sectionData.type &&
        this.props.sectionData.type === "error" ? (
          <div
            className="section-sensors-resume"
            style={{ marginTop: "1.5em" }}
          >
            <div className="model-item-title">
              {this.props.intl.formatMessage({
                id: "model-detail-resume-section-errors-title",
              })}
            </div>
            <div>{this.props.sectionData.error_message}</div>
          </div>
        ) : (
          <div
            className="section-sensors-resume"
            style={{ marginTop: "1.5em" }}
          >
            <div className="model-item-title">
              {this.props.intl.formatMessage({
                id: "model-detail-resume-section-sensors-title",
              })}
            </div>
            <MainSensorChart data={this.props.sectionData.diagnostics} />
          </div>
        )}
      </div>
    ) : null
  }
}

export default injectIntl(AnomalySectionInfo)
