import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Button, Form, Tooltip, Icon, Select } from "antd"

import { FormControl, InputLabel } from "@material-ui/core"
import errorIcon from "../../assets/images/Icon-Form-Error.png"
import { checkPermissions } from "../../services/auth/permissions/permissions"

const { Option } = Select

export class InferenceForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      labelFocus: {
        sampling_rate: "empty",
        delay: "empty",
      },
      availableRates: [
        { value: "PT5M", disabled: true },
        { value: "PT10M", disabled: true },
        { value: "PT15M", disabled: true },
        { value: "PT30M", disabled: true },
        { value: "PT1H", disabled: true },
      ],
    }

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })
  }

  componentDidMount() {
    this._limitRanges()
  }

  _limitRanges() {
    const invalidRates = ["PT1S", "PT5S", "PT10S", "PT15S", "PT30S", "PT1M"]
    let found = invalidRates.includes(this.props.minFrequency)
    let availableRates = this.state.availableRates.map(e => {
      if (e.value === this.props.minFrequency) {
        found = true
      }
      if (found) {
        e.disabled = false
      }
      return e
    })
    this.setState({ availableRates })
  }

  _renderErrorIcon(key) {
    return this.props.form.getFieldError(key) ? (
      <img
        src={errorIcon}
        alt="error-icon"
        style={{
          zIndex: "10",
          background: "white",
          position: "absolute",
          bottom: "-3px",
          right: "10px",
        }}
      ></img>
    ) : (
      ""
    )
  }

  _setLabelFocus(key) {
    const labelFocus = this.state.labelFocus
    if (labelFocus[key] === "empty") {
      this.setState({
        labelFocus: {
          ...labelFocus,
          [key]: "focused",
        },
      })
    }
  }

  _clearLabelFocus() {
    const labelFocus = this.state.labelFocus
    for (let k in labelFocus) {
      if (labelFocus[k] === "focused") {
        labelFocus[k] = "empty"
      }
    }
    this.setState({ labelFocus })
  }

  _onSelect(key) {
    this.setState({
      labelFocus: { ...this.state.labelFocus, [key]: "setted" },
    })
  }

  _submitForm() {
    this.props.form.validateFields(err => {
      if (err) {
        return message.error(
          this._formatMessage("page-user-form-generic-error-text")
        )
      } else {
        this.props.onSubmit(this.props.form.getFieldsValue())
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div style={{ maxWidth: "450px" }}>
        <div className="no-inference-text" style={{ margin: "20px 0" }}>
          {this._formatMessage("page-dataset-no-inference")}
        </div>
        {checkPermissions(["inference:write"]) ? (
          <Form className="formCustom">
            <Form.Item>
              <div className="model-item-title">
                {this._formatMessage("program-inference")}
                <Tooltip
                  title={this._formatMessage("new-model-sampling-rate-tooltip")}
                >
                  <Icon
                    type="info-circle"
                    style={{ paddingLeft: "10px", color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              </div>
              <FormControl
                variant="outlined"
                className="form-item knolar-dataset-input"
                fullWidth
              >
                <InputLabel
                  className={`knolar-input-label height-50 ${this.state.labelFocus.sampling_rate}`}
                  htmlFor="sampling_rate"
                >
                  {this._formatMessage("new-inference-form-frequency")}
                </InputLabel>
                {getFieldDecorator("sampling_rate", {
                  initialValue: [],
                  rules: [
                    {
                      required: true,
                      message: this._formatMessage("form-required-field"),
                    },
                  ],
                })(
                  <Select
                    name="sampling_rate"
                    className="knolar-select height-50"
                    onFocus={() => this._setLabelFocus("sampling_rate")}
                    onBlur={() => this._clearLabelFocus()}
                    style={{ width: "100%" }}
                    onChange={() => this._onSelect("sampling_rate")}
                    showSearch={true}
                  >
                    {this.state.availableRates &&
                      this.state.availableRates.map(({ value, disabled }) => (
                        <Option key={value} value={value} disabled={disabled}>
                          {this._formatMessage(value)}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormControl>
              {this._renderErrorIcon("sampling_rate")}
            </Form.Item>
            <Form.Item>
              <div className="model-item-title">
                {this._formatMessage("new-inference-form-delay")}
                <Tooltip title={this._formatMessage("new-model-delay-tooltip")}>
                  <Icon
                    type="info-circle"
                    style={{ paddingLeft: "10px", color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              </div>
              <FormControl
                variant="outlined"
                className="form-item knolar-dataset-input"
                fullWidth
              >
                <InputLabel
                  className={`knolar-input-label height-50 ${this.state.labelFocus.delay}`}
                  htmlFor="delay"
                >
                  Delay
                </InputLabel>
                {getFieldDecorator("delay", {
                  initialValue: [],
                  rules: [
                    {
                      required: true,
                      message: this._formatMessage("form-required-field"),
                    },
                  ],
                })(
                  <Select
                    name="delay"
                    className="knolar-select height-50"
                    onFocus={() => this._setLabelFocus("delay")}
                    onBlur={() => this._clearLabelFocus()}
                    style={{ width: "100%" }}
                    onChange={() => this._onSelect("delay")}
                    showSearch={true}
                  >
                    {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45].map(value => (
                      <Option key={value} value={value}>
                        {value} {this._formatMessage("min")}s
                      </Option>
                    ))}
                  </Select>
                )}
              </FormControl>
              {this._renderErrorIcon("delay")}
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                onClick={this._submitForm.bind(this)}
                className="knolar-button dataset-button"
              >
                {this._formatMessage("program-inference")}
              </Button>
            </div>
          </Form>
        ) : null}
      </div>
    )
  }
}

export default injectIntl(
  Form.create({ name: "inference_form" })(InferenceForm)
)
